import {Component} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Model} from '../models';
import {Model as Product} from '../../prodotti/models';
import {BehaviorSubject, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../core/store/store.module';
import {getRecords as getProdotti} from '../../prodotti/store/list/selectors';
import {loadRecords as loadProdotti} from '../../prodotti/store/list/actions';
import {saveRecord} from '../store/list/actions';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {getErrors} from '../store/errors/selectors';
import {Cliente} from '../../../models/Cliente';
import {FeatureService as ClientiService} from '../../clienti/feature.service';

@Component({
  template: `

    <div class="modal-header">
      <h4 class="modal-title">Preventivo</h4>
    </div>
    <div class="modal-body">

      <app-laravel-errors [errors]="errors$ | async"></app-laravel-errors>

    <form [formGroup]="form">


      <div class="form-group row" *ngIf="form.value.in_rubrica">
        <label class="col-form-label">Cliente</label>
        <div class="control-input">
          <ng-select
            [placeholder]="'Seleziona cliente'"
            [items]="clienti$ | async"
            formControlName="cliente_id"
            bindLabel="fullName"
            bindValue="id"
            [closeOnSelect]="true"
          >
            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
              <div class="ng-option disabled"><button class="btn btn-primary btn-sm" (click)="handleNewCliente(searchTerm)">Usa cliente non in rubrica: "{{ searchTerm }}"</button></div>
            </ng-template>
          </ng-select>
          <span *ngIf="errors && errors.errors && errors.errors.cliente_id" class="invalid-feedback" role="alert">{{ errors.errors.cliente_id[0] }}</span>
        </div>
      </div>

      <div class="form-group row" *ngIf="!form.value.in_rubrica">
        <label class="col-form-label">Cliente</label>
        <div class="control-input">
          <div class="name-input-container">
            <input autocomplete="off" class="form-control" type="text" formControlName="nome_cliente" name="nome_cliente"
                   [ngClass]="{'is-invalid': errors && errors.errors && errors.errors.nome_cliente }" required>

            <!--<button (click)="handleResetName()" class="btn btn-clean p-1"><i class="mdi mdi-close"></i></button>-->
          </div>
          <span *ngIf="errors && errors.errors && errors.errors.nome_cliente" class="invalid-feedback" role="alert">
                            {{ errors.errors.nome_cliente[0] }}
                        </span>
        </div>
      </div>

        <div class="form-group row">
          <label class="col-form-label">Note</label>
          <div class="control-input">
          <textarea class="form-control" type="text" formControlName="note"
                    [ngClass]="{'is-invalid': errors && errors.errors && errors.errors.note }"></textarea>
            <span *ngIf="errors && errors.errors && errors.errors.note" class="invalid-feedback"
                  role="alert">{{ errors.errors.note[0] }}</span>
          </div>
        </div>

        <table class="table">
          <thead>
          <tr>
            <th>Trattamento</th>
            <th>Q.tà</th>
            <th>Costo unit.</th>
            <th class="text-right" width="90">Totale</th>
          </tr>
          </thead>
          <tbody formArrayName="trattamento">
          <tr *ngFor="let _ of formTrattamento.controls; index as i">
            <ng-container [formGroupName]="i">
              <td>
                <div class="mt-3">{{ _.get('name').value }}</div>
              </td>
              <td><input class="form-control" type="number" placeholder="0" formControlName="qta"/></td>
              <td><input class="form-control"
                         [ngClass]="{'is-invalid': errors && errors.errors && errors.errors['trattamento.' + i] }"
                         type="number" step="0.01" placeholder="0,00" formControlName="costo"/></td>
              <td class="text-right">
                <div class="mt-3">{{ _.get('qta').value * _.get('costo').value | currency: '€' }}</div>
              </td>
            </ng-container>
          </tr>
          </tbody>
        </table>

        <h5><strong>Elenco prodotti</strong></h5>

        <app-products-selector filtro="ordine" [selectedProducts]="[]" [note]="false" (onAdd)="handleAddProduct($event)"></app-products-selector>

        <table class="table">
          <thead>
          <tr>
            <th>Prodotto</th>
            <th class="text-center">Omaggio</th>
            <th class="text-right" width="130">Costo</th>
          </tr>
          </thead>
          <tbody formArrayName="prodotti">
          <tr *ngFor="let _ of formProdotti.controls; index as i">
            <ng-container [formGroupName]="i">
              <td>
                <div class="d-flex align-items-center mr-3">
                  <button (click)="handleDeleteProduct(i)" class="btn-danger btn btn-sm btn-icon mr-3" style="width: 40px"><i
                    class="flaticon2-trash"></i></button>
                  {{ _.get('name').value }}
                </div>
              </td>
              <td class="text-center">
                <input type="checkbox" style="margin-top: 15px" formControlName="omaggio">
              </td>
              <td>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">€</div>
                  </div>
                  <input type="number" step="0.01" class="form-control" style="width: 50px" formControlName="costo">
                </div>
              </td>
            </ng-container>
          </tr>
          </tbody>
        </table>

        <table class="table" style="margin-top: 30px">
          <thead>
          <tr>
            <th>Promozioni</th>
            <th class="text-right" width="130">Importo</th>
          </tr>
          </thead>
          <tbody formGroupName="promozioni">
          <tr>
            <td>Sconto trattamento</td>
            <td>
              <div class="input-group mb-2">
                <input type="text" class="form-control" style="width: 50px" formControlName="sconto_trattamento">
                <div class="input-group-append">
                  <div class="input-group-text">%</div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Sconto prodotti</td>
            <td>
              <div class="input-group mb-2">
                <input type="text" class="form-control" style="width: 50px" formControlName="sconto_prodotti">
                <div class="input-group-append">
                  <div class="input-group-text">%</div>
                </div>
              </div>
            </td>
          </tr>
          <!--
          <tr>
            <td>Omaggio prodotto</td>
            <td>
              <label class="checkbox">
                <input type="checkbox" formControlName="prodotto">
                <span></span>
                Si
              </label>
            </td>
          </tr>
          -->
          <tr>
            <td>Omaggio ultimo trattamento</td>
            <td>
              <label class="checkbox">
                <input type="checkbox" formControlName="trattamento">
                <span></span>
                Si
              </label>
            </td>
          </tr>
          </tbody>
        </table>

        <hr style="margin-top: 50px;">

        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale trattamento:</div>
          <div>{{ getTotaleTrattamento() | currency: '€'}}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale prodotti:</div>
          <div>{{ getTotaleProdotti() | currency: '€'}}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Sconto trattamento:</div>
          <div>-{{ getScontoTrattamento() | currency: '€'}}</div>
        </div>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Sconto prodotti:</div>
          <div>-{{ getScontoProdotti() | currency: '€'}}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale:</div>
          <div>{{ getTotaleProdotti() + getTotaleTrattamento() - getTotalePromo() | currency: '€'}}</div>
        </div>

    </form>

    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" (click)="modal.dismiss()" class="btn btn-light">Annulla</button>
      <button [disabled]="form.invalid" type="button" class="btn btn-primary" (click)="handleSubmit()">Salva</button>
    </div>

  `
})

export class PreventivoModalComponent {

  public record$ = new Subject();
  form: FormGroup;
  formTrattamento: FormArray;
  formProdotti: FormArray;

  clienti$: BehaviorSubject<Cliente[]> = new BehaviorSubject([]);
  prodotti$ = this.store.select(getProdotti);
  errors$ = this.store.select(getErrors);

  loading = false;
  options = null;
  errors = null;
  reference = null;
  file = null;

  constructor(
    clientiService: ClientiService,
    fb: FormBuilder,
    private store: Store<AppState>,
    public modal: NgbActiveModal
  ) {

    clientiService.all({ withoutInactive: true }).subscribe((records: Cliente[]) => this.clienti$.next(records));

    this.form = fb.group({
      id: fb.control(null),
      in_rubrica: fb.control(true),
      cliente_id: fb.control(null),
      nome_cliente: fb.control(null),
      telefono: fb.control(null),
      note: fb.control(null),
      trattamento: new FormArray([
        new FormGroup({
          id: new FormControl(1),
          name: new FormControl('2 volte alla sett.'),
          qta: new FormControl(0),
          costo: new FormControl(0),
        }),
        new FormGroup({
          id: new FormControl(2),
          name: new FormControl('1 volta alla sett.'),
          qta: new FormControl(0),
          costo: new FormControl(0),
        }),
        new FormGroup({
          id: new FormControl(3),
          name: new FormControl('1 volta ogni 15 gg'),
          qta: new FormControl(0),
          costo: new FormControl(0, ),
        }),
        new FormGroup({
          id: new FormControl(3),
          name: new FormControl('1 volta al mese'),
          qta: new FormControl(0),
          costo: new FormControl(0, ),
        }),
        new FormGroup({
          id: new FormControl(4),
          name: new FormControl('Mantenimento'),
          qta: new FormControl(0),
          costo: new FormControl(0),
        })
      ]),
      prodotti: new FormArray([]),
      promozioni: new FormGroup({
        sconto_trattamento: new FormControl(0),
        sconto_prodotti: new FormControl(0),
        prodotto: new FormControl(false),
        trattamento: new FormControl(false),
      })
    });

    this.formTrattamento = this.form.get('trattamento') as FormArray;
    this.formProdotti = this.form.get('prodotti') as FormArray;

    this.handleSubmit = this.handleSubmit.bind(this);

    this.record$.subscribe((record: Model) => {
      if (record) {

        this.form.patchValue({...record, in_rubrica: !!record.cliente_id });
        record.prodotti.map(prodotto => {
          this.formProdotti.push(
            new FormGroup({
              id: new FormControl(prodotto.id),
              name: new FormControl(prodotto.name),
              costo: new FormControl(prodotto.user_price ? prodotto.user_price : prodotto.price),
              omaggio: new FormControl(prodotto.omaggio)
            })
          );
        });
        this.formProdotti.patchValue(record.prodotti);
        this.formTrattamento.patchValue(record.trattamento);
      }
    });

    this.store.dispatch(loadProdotti({ reload: false }));

  }

  handleSubmit() {

    this.store.dispatch(saveRecord({ record: this.form.value }));

  }

  handleAddProduct(product: Product) {

    if (product) {

      const findIndex = this.formProdotti.value.findIndex(_ => _.id === product.id);

      if (findIndex === -1) {
        this.formProdotti.push(
          new FormGroup({
            id: new FormControl(product.id),
            name: new FormControl(product.name),
            costo: new FormControl(product.user_price ? product.user_price : product.price),
            omaggio: new FormControl(false)
          })
        );
      }

    }

  }

  handleDeleteProduct(index) {

    this.formProdotti.removeAt(index);

  }

  getTotaleTrattamento() {

    return this.formTrattamento.value.reduce((value, item) => value + (parseFloat(item.costo) * parseInt(item.qta)), 0);

  }

  getTotaleProdotti() {

    return this.formProdotti.value.reduce((value, item) => value + (!item.omaggio ? parseFloat(item.costo) : 0), 0);

  }

  getScontoTrattamento() {

    const totale = this.getTotaleTrattamento();
    const percentuale = parseInt(this.form.value.promozioni.sconto_trattamento);
    return totale * (percentuale / 100);

  }

  getScontoProdotti() {

    const totale = this.getTotaleProdotti();
    const percentuale = parseInt(this.form.value.promozioni.sconto_prodotti);
    return totale * (percentuale / 100);

  }

  getTotalePromo() {

    return this.getScontoProdotti() + this.getScontoTrattamento();

  }

  handleNewCliente(nome_cliente: string) {

    this.form.patchValue({
      in_rubrica: false,
      nome_cliente
    });

  }
}
