

        <form [formGroup]="form" class="form-group row">

            <label class="col-form-label">Data</label>
            <div class="control-input">
                <app-datetime-picker (onDateChange)="handleDateChange($event)" formControlName="data"></app-datetime-picker>

                <div *ngIf="errors && errors.errors && errors.errors.data"
                     class="invalid-feedback"
                     role="alert">
                    {{ errors.errors.data[0] }}

                    <div *ngIf="errors.errors.data[0].indexOf('Esiste') === 0">
                        <label class="checkbox checkbox-danger">
                            <input type="checkbox" formControlName="force">
                            <span></span>Procedi comunque
                        </label>
                    </div>
                </div>

                <div *ngIf="pianificaFuturi">
                    <label class="checkbox checkbox-success mt-5">
                        <input type="checkbox" formControlName="calcola">
                        <span></span>Calcola automaticamente le date dei prossimi appuntamenti.<br />
                      <small>Ricorda che il calcolo potrebbe coincidere con giorni di chiusura non ancora programmati o festività. Controllale sempre prima di confermarle al cliente.</small>
                    </label>
                </div>

            </div>
        </form>
    