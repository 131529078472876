/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@ng-select/ng-select/ng-select.ngfactory";
import * as i2 from "@ng-select/ng-select";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./linee-prodotto-selector.component.ngfactory";
import * as i6 from "./linee-prodotto-selector.component";
import * as i7 from "@ngrx/store";
import * as i8 from "../../features/prodotti/feature.service";
import * as i9 from "@angular/cdk/drag-drop";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "./products-selector.component";
var styles_ProductsSelectorComponent = [];
var RenderType_ProductsSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductsSelectorComponent, data: {} });
export { RenderType_ProductsSelectorComponent as RenderType_ProductsSelectorComponent };
function View_ProductsSelectorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "CategoryBox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "CategoryBox_Color"]], [[4, "background", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "CategoryBox_Text"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.item.category == null) ? null : _v.context.item.category.color); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.item.name; _ck(_v, 3, 0, currVal_1); }); }
function View_ProductsSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 26, "table", [["width", "100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 25, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 21, "td", [["class", "pt-0"], ["style", "border-top: 0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 20, "ng-select", [["bindLabel", "name"], ["class", "select-product ng-select"], ["role", "listbox"]], [[2, "ng-select-single", null], [2, "ng-select-typeahead", null], [2, "ng-select-multiple", null], [2, "ng-select-taggable", null], [2, "ng-select-searchable", null], [2, "ng-select-clearable", null], [2, "ng-select-opened", null], [2, "ng-select-disabled", null], [2, "ng-select-filtered", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).handleKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.selectedProduct = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_NgSelectComponent_0, i1.RenderType_NgSelectComponent)), i0.ɵprd(4608, null, i2.ɵd, i2.ɵd, []), i0.ɵdid(5, 4964352, null, 12, i2.NgSelectComponent, [[8, "select-product"], [8, null], [8, null], i2.NgSelectConfig, i2.SELECTION_MODEL_FACTORY, i0.ElementRef, i0.ChangeDetectorRef, i2.ɵr], { bindLabel: [0, "bindLabel"], closeOnSelect: [1, "closeOnSelect"], searchFn: [2, "searchFn"], multiple: [3, "multiple"], items: [4, "items"] }, null), i0.ɵqud(335544320, 1, { optionTemplate: 0 }), i0.ɵqud(335544320, 2, { optgroupTemplate: 0 }), i0.ɵqud(335544320, 3, { labelTemplate: 0 }), i0.ɵqud(335544320, 4, { multiLabelTemplate: 0 }), i0.ɵqud(335544320, 5, { headerTemplate: 0 }), i0.ɵqud(335544320, 6, { footerTemplate: 0 }), i0.ɵqud(335544320, 7, { notFoundTemplate: 0 }), i0.ɵqud(335544320, 8, { typeToSearchTemplate: 0 }), i0.ɵqud(335544320, 9, { loadingTextTemplate: 0 }), i0.ɵqud(335544320, 10, { tagTemplate: 0 }), i0.ɵqud(335544320, 11, { loadingSpinnerTemplate: 0 }), i0.ɵqud(603979776, 12, { ngOptions: 1 }), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.NgSelectComponent]), i0.ɵdid(19, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(21, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵand(0, [[1, 2]], null, 1, null, View_ProductsSelectorComponent_3)), i0.ɵdid(23, 16384, null, 0, i2.ɵe, [i0.TemplateRef], null, null), (_l()(), i0.ɵeld(24, 0, null, null, 2, "td", [["class", "text-right pt-0"], ["style", "border-top: 0"], ["width", "60"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleAddProdotto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 0, "span", [["class", "flaticon2-add"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_16 = "name"; var currVal_17 = true; var currVal_18 = _co.customSearchFn; var currVal_19 = false; var currVal_20 = _co.productsList; _ck(_v, 5, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_21 = _co.selectedProduct; _ck(_v, 19, 0, currVal_21); }, function (_ck, _v) { var currVal_0 = !i0.ɵnov(_v, 5).multiple; var currVal_1 = i0.ɵnov(_v, 5).typeahead; var currVal_2 = i0.ɵnov(_v, 5).multiple; var currVal_3 = i0.ɵnov(_v, 5).addTag; var currVal_4 = i0.ɵnov(_v, 5).searchable; var currVal_5 = i0.ɵnov(_v, 5).clearable; var currVal_6 = i0.ɵnov(_v, 5).isOpen; var currVal_7 = i0.ɵnov(_v, 5).disabled; var currVal_8 = i0.ɵnov(_v, 5).filtered; var currVal_9 = i0.ɵnov(_v, 21).ngClassUntouched; var currVal_10 = i0.ɵnov(_v, 21).ngClassTouched; var currVal_11 = i0.ɵnov(_v, 21).ngClassPristine; var currVal_12 = i0.ɵnov(_v, 21).ngClassDirty; var currVal_13 = i0.ɵnov(_v, 21).ngClassValid; var currVal_14 = i0.ɵnov(_v, 21).ngClassInvalid; var currVal_15 = i0.ɵnov(_v, 21).ngClassPending; _ck(_v, 3, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15]); }); }
function View_ProductsSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductsSelectorComponent_2)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canEdit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProductsSelectorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-linee-prodotto-selector", [], null, [[null, "addProducts"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addProducts" === en)) {
        var pd_0 = (_co.handleAddProducts($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_LineeProdottoSelectorComponent_0, i5.RenderType_LineeProdottoSelectorComponent)), i0.ɵdid(1, 49152, null, 0, i6.LineeProdottoSelectorComponent, [i7.Store, i8.FeatureService], { filtro: [0, "filtro"], vendita: [1, "vendita"] }, { addProducts: "addProducts" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filtro; var currVal_1 = _co.vendita; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ProductsSelectorComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "input", [["class", "form-control"], ["placeholder", "Note..."], ["type", "text"]], [[8, "value", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleUpdate($event, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.note ? _v.parent.context.$implicit.note : ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ProductsSelectorComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "pt-2 text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-danger btn-sm"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRemoveProduct(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "flaticon2-trash"]], null, null, null, null, null))], null, null); }
function View_ProductsSelectorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 11, "tr", [["cdkDrag", ""], ["class", "cdk-drag"], ["style", "cursor: move"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i0.ɵprd(6144, null, i9.ɵb, null, [i9.CdkDrag]), i0.ɵdid(2, 4866048, [[13, 4]], 3, i9.CdkDrag, [i0.ElementRef, [3, i9.CDK_DROP_LIST], i4.DOCUMENT, i0.NgZone, i0.ViewContainerRef, i9.CDK_DRAG_CONFIG, [2, i10.Directionality], i9.DragDrop, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 14, { _handles: 1 }), i0.ɵqud(603979776, 15, { _previewTemplate: 0 }), i0.ɵqud(603979776, 16, { _placeholderTemplate: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 3, "td", [["class", "pt-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductsSelectorComponent_6)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductsSelectorComponent_7)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.note; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.canEdit; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).disabled; var currVal_1 = i0.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
export function View_ProductsSelectorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ProductsSelectorComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["customProducts", 2]], null, 0, null, View_ProductsSelectorComponent_4)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "table", [["cdkDropListGroup", ""], ["class", "table table-vertical-center"], ["width", "100%"]], null, null, null, null, null)), i0.ɵdid(5, 147456, null, 0, i9.CdkDropListGroup, [], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 6, "tbody", [["cdkDropList", ""], ["class", "cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.drop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(6144, null, i9.CDK_DROP_LIST, null, [i9.CdkDropList]), i0.ɵdid(8, 1196032, null, 1, i9.CdkDropList, [i0.ElementRef, i9.DragDrop, i0.ChangeDetectorRef, [2, i10.Directionality], [3, i9.CdkDropListGroup]], { data: [0, "data"] }, { dropped: "cdkDropListDropped" }), i0.ɵqud(603979776, 13, { _draggables: 1 }), i0.ɵprd(256, null, i9.CdkDropListGroup, undefined, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductsSelectorComponent_5)), i0.ɵdid(12, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.hasCustomProductsPermission$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = _co.selectedProducts; _ck(_v, 8, 0, currVal_6); var currVal_7 = _co.selectedProducts; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 8).id; var currVal_3 = i0.ɵnov(_v, 8).disabled; var currVal_4 = i0.ɵnov(_v, 8)._dropListRef.isDragging(); var currVal_5 = i0.ɵnov(_v, 8)._dropListRef.isReceiving(); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_ProductsSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-products-selector", [], null, null, null, View_ProductsSelectorComponent_0, RenderType_ProductsSelectorComponent)), i0.ɵdid(1, 49152, null, 0, i11.ProductsSelectorComponent, [i7.Store], null, null)], null, null); }
var ProductsSelectorComponentNgFactory = i0.ɵccf("app-products-selector", i11.ProductsSelectorComponent, View_ProductsSelectorComponent_Host_0, { canEdit: "canEdit", selectedProducts: "selectedProducts", note: "note", vendita: "vendita", filtro: "filtro" }, { onAdd: "onAdd", onRemove: "onRemove", onUpdateNote: "onUpdateNote" }, []);
export { ProductsSelectorComponentNgFactory as ProductsSelectorComponentNgFactory };
