import {Component, OnDestroy} from '@angular/core';
import {IModalDialog, IModalDialogButton} from '../../../packages/ngx-modal-master';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import * as itLocale from 'date-fns/locale/it';
import {DateFormatPipe as DatePipe} from '../pipes/dateformat.pipe';
import {Subject, Subscription} from 'rxjs';

@Component({
    providers: [DatePipe],
    template: `

        <form [formGroup]="form" class="form-group row">

            <label class="col-form-label">Data</label>
            <div class="control-input">
                <app-datetime-picker (onDateChange)="handleDateChange($event)" formControlName="data"></app-datetime-picker>

                <div *ngIf="errors && errors.errors && errors.errors.data"
                     class="invalid-feedback"
                     role="alert">
                    {{ errors.errors.data[0] }}

                    <div *ngIf="errors.errors.data[0].indexOf('Esiste') === 0">
                        <label class="checkbox checkbox-danger">
                            <input type="checkbox" formControlName="force">
                            <span></span>Procedi comunque
                        </label>
                    </div>
                </div>

                <div *ngIf="pianificaFuturi">
                    <label class="checkbox checkbox-success mt-5">
                        <input type="checkbox" formControlName="calcola">
                        <span></span>Calcola automaticamente le date dei prossimi appuntamenti.<br />
                      <small>Ricorda che il calcolo potrebbe coincidere con giorni di chiusura non ancora programmati o festività. Controllale sempre prima di confermarle al cliente.</small>
                    </label>
                </div>

            </div>
        </form>
    `
})

export class AppuntamentoModalComponent implements OnDestroy, IModalDialog {

    form: FormGroup;
    actionButtons: IModalDialogButton[];
    record = null;
    options = null;
    errors = null;
    pianificaFuturi = false;

    calendarDate$ = new Subject();
    calendarChangeSubscribtion$: Subscription;

    datepickerOptions = {
        minYear: 1950,
        maxYear: (new Date()).getFullYear(),
        displayFormat: 'DD/MM/YYYY',
        addClass: 'form-control',
        locale: itLocale,
        firstCalendarDay: 1,
        barTitleIfEmpty: 'Seleziona una data',
        placeholder: 'Seleziona una data',
    };

    constructor(fb: FormBuilder) {

        this.handleSubmit = this.handleSubmit.bind(this);

        this.form = fb.group({
            data: new FormControl(''),
            force: new FormControl(false),
            calcola: new FormControl(false)
        });

    }

    dialogInit(reference, options) {

        this.options = options;
        this.pianificaFuturi = options.data.hasOwnProperty('pianificaFuturi') ? options.data.pianificaFuturi : false;

        options.onValidate.subscribe({
            next: value => this.errors = value
        });

        if (options.data.start) {
            this.form.patchValue({
                data: options.data.start,
            });
        }

        if (options.data.calendarChangeEvent) {
          this.calendarChangeSubscribtion$ = this.calendarDate$.subscribe(options.data.calendarChangeEvent);
        }

        this.actionButtons = [
            {text: 'Chiudi', buttonClass: 'btn btn-default'},
            {text: 'Salva', buttonClass: 'btn btn-success', onAction: this.handleSubmit},
        ];

    }

    handleSubmit() {

        const { data, calcola } = this.form.value;
        return this.options.data.onChange({
            data,
            force: this.form.value.force,
            calcola
        });

    }

    handleDateChange(date) {
      this.calendarDate$.next(date);
    }

  ngOnDestroy(): void {
      this.calendarChangeSubscribtion$.unsubscribe();
  }


}
