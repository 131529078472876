import {createAction, props} from '@ngrx/store';
import {Cliente} from '../../../../models/Cliente';

const area = '[Customers]';

/**
 * Get records actions
 */

export const setCurrent = createAction(
    `${area} Set current`,
    props<{record: Cliente}>()
);

export const setCurrentSuccess = createAction(
    `${area} Set current success`,
);

export const updateCurrent = createAction(
    `${area} Update current`,
    props<{id: number}>()
);
