import {Injectable} from '@angular/core';
import {FeatureService} from '../../feature.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as actions from './actions';
import {getCurrentRecord} from './selectors';
import {getErrors} from '../errors/selectors';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {parseHttpResponse} from '../../../../shared/helpers';
import {Store} from '@ngrx/store';
import {Cliente} from '../../../../models/Cliente';

import {ItemModalComponent as ModalComponent} from '../../components/item-modal.component';
import * as errorActions from '../errors/actions';
import {clear} from '../errors/actions';
import {setCurrent, updateCurrent} from "../current/actions";

@Injectable()
export class FeatureEffects {

  loadRecords$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadRecords),
    switchMap(() => this.service.all()
      .pipe(
        map((records: Cliente[]) => actions.loadRecordsSuccess({records})),
        catchError(response => of(actions.loadRecordsFail(parseHttpResponse(response))))
      )
    )
  ))

  saveRecord$ = createEffect(() => this.actions$.pipe(
    ofType(actions.saveRecord),
    mergeMap(({record}) => {
      this.store.dispatch(clear());
      if (record && record.hasOwnProperty('id') && record.id.toString().trim() !== '')
        return of(actions.updateRecord({record}))
      else
        return of(actions.addRecord({record}))
    })
  ))

  addRecord$ = createEffect(() => this.actions$.pipe(
    ofType(actions.addRecord),
    mergeMap(({record}) => this.service.create(record)
      .pipe(
        switchMap((result: any) => [
          actions.addRecordSuccess({record: result.record}),
          actions.closeModal()
        ]),
        catchError(response => of(errorActions.set(parseHttpResponse(response))))
      )
    )
  ))

  updateRecord$ = createEffect(() => this.actions$.pipe(
    ofType(actions.updateRecord),
    mergeMap(({record}) => this.service.update(record.id, record)
      .pipe(
        switchMap((result: any) => [
          actions.updateRecordSuccess({record: result.record}),
          actions.closeModal()
        ]),
        catchError(response => of(errorActions.set(parseHttpResponse(response))))
      )
    )
  ))

  deleteRecord$ = createEffect(() => this.actions$.pipe(
    ofType(actions.deleteRecord),
    switchMap(({id}) => this.service.delete(id)
      .pipe(
        map(() => actions.deleteRecordSuccess({id})),
        // catchError(() => of(loadRecordsFail()))
      )
    )
  ))

  updatePrivacyFile$ = createEffect(() => this.actions$.pipe(
    ofType(actions.updatePrivacyFile),
    mergeMap(({id, file}) => this.service.updatePrivacyFile(id, file)
      .pipe(
        map((result: any) => actions.updateRecordSuccess({record: result.record})),
        catchError(response => of(actions.updateRecordFail(parseHttpResponse(response))))
      )
    )
  ))

  setActiveRecord$ = createEffect(() => this.actions$.pipe(
    ofType(actions.setActive),
    mergeMap(({id, value}) => this.service.patch(id, {active: value})
      .pipe(
        map(() => actions.setActiveSuccess({id, value})),
      )
    )
  ))

  setSmsRecord$ = createEffect(() => this.actions$.pipe(
    ofType(actions.setSms),
    mergeMap(({id, value}) => this.service.patch(id, {sms: value})
      .pipe(
        map((result: any) => actions.updateRecordSuccess({record: result.record})),
      )
    )
  ))

  setSmsPromemoriaRecord$ = createEffect(() => this.actions$.pipe(
    ofType(actions.setSmsPromemoria),
    mergeMap(({id, value}) => this.service.patch(id, {sms_promemoria: value})
      .pipe(
        map((result: any) => actions.updateRecordSuccess({record: result.record})),
      )
    )
  ))

  showModal$ = createEffect(() => this.actions$.pipe(
    ofType(actions.showModal),
    withLatestFrom(this.store.select(getCurrentRecord)),
    tap(([{ id }, record]) => {
      const modal = this.modal.open(ModalComponent);
      const subscription = this.store.select(getErrors).subscribe(errors => {
        modal.componentInstance.errors = errors
      });
      modal.result.then(() => null).catch(() => {
        this.store.dispatch(errorActions.clear());
        subscription.unsubscribe();
      });
      if (id) {
        modal.componentInstance.record = record;
      }
    })
  ), {dispatch: false})

  closeModal$ = createEffect(() => this.actions$.pipe(
    ofType(actions.closeModal),
    tap(() => {
      this.modal.dismissAll();
    })
  ), {dispatch: false});


  updateCurrent$ = createEffect(() => this.actions$.pipe(
    ofType(updateCurrent),
    switchMap(({id}) => this.service.item(id)
      .pipe(
        map((record: any) => setCurrent({ record })),
      )
    )
  ));

  constructor(
    private service: FeatureService,
    private store: Store,
    private actions$: Actions,
    private modal: NgbModal
  ) {
  }

}
