import { ApiClient } from '../../../../core/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/services/api.service";
export class FeatureService {
    constructor(http) {
        this.http = http;
        this.apiSegment = 'clienti';
    }
    all(clienteId, guida = false) {
        return this.http.get(`${this.apiSegment}/${clienteId}/foto?guida=${guida}`);
    }
    cartelle(clienteId) {
        return this.http.get(`${this.apiSegment}/${clienteId}/foto/cartelle`);
    }
    create(clienteId, data) {
        return this.http.post(`${this.apiSegment}/${clienteId}/foto`, data);
    }
    delete(clienteId, id) {
        return this.http.delete(`${this.apiSegment}/${clienteId}/foto/${id}`);
    }
    order(clienteId, ids) {
        return this.http.put(`${this.apiSegment}/${clienteId}/foto`, {
            order: ids
        });
    }
    print(clienteId, id1, id2) {
        return this.http.getEndpoint(`${this.apiSegment}/${clienteId}/foto/print/${id1}_${id2}`);
    }
    download(clienteId, id) {
        return this.http.getEndpoint(`${this.apiSegment}/${clienteId}/foto/${id}/download`);
    }
    postTemp(data) {
        // @ts-ignore
        return this.http.post(`user/temp-foto`, data);
    }
    updateTemp(id, data) {
        return this.http.post(`user/temp-foto/${id}`, Object.assign({ _method: 'put' }, data));
    }
    deleteTemp(id) {
        return this.http.post(`user/temp-foto/${id}`, {
            _method: 'delete'
        });
    }
}
FeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.ɵɵinject(i1.ApiClient)); }, token: FeatureService, providedIn: "root" });
